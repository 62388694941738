import axios, { type AxiosInstance, type AxiosResponse } from "axios";
import JwtService from "@/services/JwtService";

export interface ApiError {
  title?: string;
  status?: number;
  detail?: string;
  errorKey?: string;
}

class ApiService {
  public static axios: AxiosInstance;

  public static init() {
    ApiService.axios = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      timeout: 10000,
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
      }
    });
    ApiService.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    if (JwtService.getAccessToken()) {
      ApiService.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getAccessToken()}`;
    }
  }

  public static query(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.axios.get(resource, params);
  }

  public static get(
    resource: string,
  ): Promise<AxiosResponse> {
    return ApiService.axios.get(`${resource}`);
  }

  public static post(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.axios.post(`${resource}`, params);
  }

  public static request(params: any): Promise<AxiosResponse> {
    return ApiService.axios.request(params);
  }

  public static patch(
    resource: string,
    params: any,
  ): Promise<AxiosResponse> {
    return ApiService.axios.patch(`${resource}`, params);
  }

  public static put(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.axios.put(`${resource}`, params);
  }

  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.axios.delete(resource);
  }
}

export default ApiService;
