import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import AppLayout from '@/layout/AppLayout.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      meta: {
        middleware: 'auth',
      },
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/views/DashboardView.vue'),
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/pages/UsersPage.vue'),
        },
        {
          path: '/categories',
          name: 'categories',
          component: () => import('@/views/pages/CategoryPage.vue'),
        },
        {
          path: '/stickers',
          name: 'stickers',
          component: () => import('@/views/pages/StickersPage.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../views/LogoutView.vue'),
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  if (!to.meta.middleware || to.meta.middleware !== 'auth') {
    return next();
  }

  const authStore = useAuthStore();

  // verify auth token before each page change
  // await authStore.verifyAuth();

  // before page access check if page requires authentication
  if (authStore.isAuthenticated) {
    next();
  } else {
    next({ name: 'login' });
  }
});

export default router;
