import { ref } from 'vue';
import { defineStore } from 'pinia';
import ApiService from '@/services/ApiService';
import JwtService from '@/services/JwtService';

export interface User {
  id: string;
  email: string;
  password: string;
}

export interface LoginResponse {
  user: User;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    const errors = ref({});
    const user = ref<User>({} as User);
    const isAuthenticated = ref(!!JwtService.getAccessToken());

    function setAuth(loginResponse: LoginResponse) {
      isAuthenticated.value = true;
      user.value = loginResponse.user;
      errors.value = {};
      JwtService.saveAccessToken(loginResponse.tokens.accessToken);
      JwtService.saveRefreshToken(loginResponse.tokens.refreshToken);
    }

    function setError(error: any) {
      errors.value = { ...error };
    }

    function purgeAuth() {
      isAuthenticated.value = false;
      user.value = {} as User;
      errors.value = [];
      JwtService.destroyTokens();
    }

    function login(credentials: User) {
      return ApiService.post('/admin/auth/login', credentials)
        .then(({ data }) => {
          setAuth(data);
          ApiService.setHeader(); // update accessToken
        })
        .catch(({ response }) => {
          setError(
            response?.data
              ? response.data.errors
              : 'Erro de comunicação com o servidor'
          );
        });
    }

    function logout() {
      purgeAuth();
    }

    function forgotPassword(email: string) {
      return ApiService.post('users/forgot_password', email)
        .then(() => {
          setError({});
        })
        .catch(({ response }) => {
          setError(response.data.errors);
        });
    }

    function verifyAuth() {
      if (JwtService.getAccessToken()) {
        ApiService.setHeader();
        ApiService.post('users/verify_token', {
          accesstoken: JwtService.getAccessToken(),
        })
          .then(({ data }) => {
            setAuth(data);
          })
          .catch(({ response }) => {
            setError(response.data.errors);
            purgeAuth();
          });
      } else {
        purgeAuth();
      }
    }

    return {
      errors,
      user,
      isAuthenticated,
      login,
      logout,
      forgotPassword,
      verifyAuth,
    };
  },
  {
    persist: true,
  }
);
