<script setup lang="ts">
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'Cadastros',
        items: [
            { label: 'Usuários', icon: 'pi pi-fw pi-user', to: '/users' },
            { label: 'Categorias', icon: 'pi pi-fw pi-folder', to: '/categories', badge: 'NEW' },
            { label: 'Criativos', icon: 'pi pi-fw pi-star', to: '/stickers', badge: 'NEW' }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>
