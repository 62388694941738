const ACCESS_TOKEN_KEY = 'accessToken' as string;
const REFRESH_TOKEN_KEY = 'refreshToken' as string;

export const getAccessToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const saveAccessToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const destroyAccessToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const saveRefreshToken = (token: string): void => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const destroyTokens = (): void => {
  destroyAccessToken();
  destroyRefreshToken();
};

export default {
  getAccessToken,
  saveAccessToken,
  destroyAccessToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  destroyTokens,
};
